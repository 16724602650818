.sidebar{
    padding: 0 0 0 15%;
    @media(max-width:769px){
        transition: 1s;
    }

    .logo{
        width: 150px;
        margin: 20px 0 40px;
        @media(max-width:992px){
            width: 100px;
        }
    }

    &-portal{
        &-heading{
            color: #333333;
            font-size: 19px !important;
            margin-bottom: 30px;
        }
        &-links{
            display: flex;
            margin: 10px 0;
            cursor: pointer;
            p{
                margin-left: 10px;
            }
        }
        &-dashboardlink{
            // display: flex;
            // align-items: center;
            // flex-direction: row;

            &-main{
            display: flex;
            // align-items: center;
            justify-content: center;
            // flex-direction: row;

            }
            &-mainlink{
 
                margin:0.5rem 0 0.8rem 1rem;
             
            }
            a{
                color: #787676 !important;
                text-decoration: none !important;
                outline: none !important;;
            }
        }

        .active{
            display: flex;
            margin: 10px 0;
            cursor: pointer;
            p{
                margin-left: 10px;
                color: #333333;
            }
            path{
                fill:#333333;
            }
        }

        .inactive{
            display: flex;
            margin: 10px 0;
            cursor: pointer;
            p{
                margin-left: 10px;
                color: #787676;
            }
            path{
                fill:#787676;
            }
        }

        .signout{
            margin-top: 45vh;
        }
    }
}