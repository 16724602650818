*,
*::after,
*::before {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  overflow-x: hidden;
}


input:focus, select:focus, textarea:focus{
  outline: 1px solid #ccc;
  border-radius: 10px;
}