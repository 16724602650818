body {
  margin: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Nunito Sans", sans-serif;
}

h1, h2, h3, h4, h5, h6, p, span{
  font-family: "Nunito Sans", sans-serif;
}

.link-decor{
  text-decoration: none;
}

.disp-flex{
  display: flex;
  align-items: baseline;
}

.pos-rel{
  position: absolute;
}

.toggle-button.react-toggle--checked .react-toggle-track {
  background-color: #773DD3;
}

.hide{
  display: none;
}
.link-decoration {
  text-decoration: none;
}